import { XF } from "./XF";

/** basic 0-9 -> 00-09 leftpad */
function pad(number: number) {
  return `${number < 10 ? "0" : ""}${number}`;
}

XF.Element.extend("date-input", {
  init() {
    let { minDate, maxDate } = this.options;
    if (minDate) {
      const minTime = Date.parse(minDate.replace(/-/g, "/"));
      minDate = new Date(minTime);
    }
    if (maxDate) {
      const maxTime = Date.parse(maxDate.replace(/-/g, "/"));
      maxDate = new Date(maxTime);
    }

    const { $target } = this;
    const initialValue = $target.val();
    const config: ConstructorParameters<typeof window.Pikaday>[0] = {
      field: this.$target[0],
      onSelect: (date: Date) => {
        const day = date.getDate();
        const month = date.getMonth() + 1;
        const year = date.getFullYear();
        this.$target.val(`${year}-${pad(month)}-${pad(day)}`);
      },
      onOpen() {
        if ($target.prop("readonly")) {
          this.hide();
        }
      },
      parse(dateString) {
        const match = dateString.match(/^(\d{4})-(\d\d?)-(\d\d?)$/);

        if (match) {
          return new Date(
            parseInt(match[1], 10),
            parseInt(match[2], 10) - 1,
            parseInt(match[3], 10)
          );
        }

        return new Date(dateString);
      },
      showTime: false,
      firstDay: this.options.weekStart,
      minDate,
      maxDate,
      disableWeekends: this.options.disableWeekends,
      yearRange: this.options.yearRange,
      showWeekNumber: this.options.showWeekNumber,
      showDaysInNextAndPreviousMonths:
        this.options.showDaysInNextAndPreviousMonths,
      i18n: {
        previousMonth: "",
        nextMonth: "",
        weekdays: [0, 1, 2, 3, 4, 5, 6].map((day) => {
          return XF.phrase(`day${day}`);
        }),
        weekdaysShort: [0, 1, 2, 3, 4, 5, 6].map((day) => {
          return XF.phrase(`dayShort${day}`);
        }),
        months: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11].map((month) => {
          return XF.phrase(`month${month}`);
        }),
      },
      isRTL: XF.isRtl(),
    };

    if (initialValue) {
      // Pikaday uses Date.parse() internally which parses yyyy-mm-dd unexpectedly when in UTC-X timezones.
      // This works around that issue.
      const match = initialValue.match(/^(\d{4})-(\d\d?)-(\d\d?)$/);
      if (match) {
        config.defaultDate = new Date(
          parseInt(match[1], 10),
          parseInt(match[2], 10) - 1,
          parseInt(match[3], 10)
        );
        config.setDefaultDate = true;
      }
    }

    // eslint-disable-next-line no-new
    new window.Pikaday(config);
    this.$target.val(initialValue);
  },
});

XF.Element.extend("number-box", {
  createButton(dir) {
    const ariaText =
      dir === "up" ? "Increment page number" : "Decrement page number";
    const $button = $("<button />")
      .attr("type", "button")
      .attr("tabindex", "-1")
      .attr("aria-label", ariaText)
      .addClass("inputGroup-text")
      .addClass("inputNumber-button")
      .addClass(`inputNumber-button--${dir}`)
      .addClass(`js-${dir}`)
      .attr("data-dir", dir);

    if (this.$textInput.prop("disabled")) {
      $button.addClass("is-disabled").prop("disabled", true);
    }

    if (this.options.buttonSmaller) {
      $button.addClass("inputNumber-button--smaller");
    }

    return $button;
  },
});

XF.Element.extend("ajax-submit", {
  parentClass: undefined as unknown as typeof XF.AjaxSubmit,
  init() {
    this.parentClass = Object.getPrototypeOf(Object.getPrototypeOf(this));
    this.parentClass.init.call(this);

    if (this.shouldKeepButtonDisabled()) {
      $(this.$target?.[0]).on("ajax-submit:error", () => {
        this.parentClass.enableButtons.call(this);
      });
    }
  },

  enableButtons() {
    if (this.shouldKeepButtonDisabled()) {
      return;
    }
    this.parentClass.enableButtons.call(this);
  },

  shouldKeepButtonDisabled() {
    return this.$target && this.$target.data("keep-buttons-disabled") === true;
  },
});
